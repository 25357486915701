import axios from '@axios'

export default {
  async getMandant(mandantID) {
    try {
      const r = await axios.get('benutzers/getMandantGet', {
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getMandantBenutzer(mandantID) {
    // console.log('getBenutzerManant - called')
    // console.log(mandantID)
    try {
      const r = await axios.get('benutzers/getBenutzerMandantGet', {
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getMandantBenutzerSel(mandantID) {
    try {
      const r = await axios.get('benutzers/getBenutzerMandantSel', {
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putMandant(data) {
    try {
      const r = await axios.get('benutzers/getMandantUpdate', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async createMandant(data) {
    try {
      const r = await axios.get('benutzers/getMandantInsert', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async postMandantLogo(datei, mandantID) {
    const formData = new FormData()
    try {
      formData.append('logo', datei)
      const r = await axios.post('benutzers/postMandantLogo', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getMandantLogo(mandantID) {
    try {
      const r = await axios.get('benutzers/getMandantLogo', {
        responseType: 'blob',
        headers: { Accept: 'image/png' },
        params: {
          mandantID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
}
