<template>
  <div v-if="!udata">Loading Please wait...
  </div>
  <div v-else>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isBenutzerEditSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-benutzer-edit-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ $g.manUserEdit ? 'Benutzer ändern' : 'Neuen Benutzer erfassen' }}
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ invalid }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="save"
          >
            <b-form-group
              label="Login"
              label-for="login"
            >
              <b-form-input
                v-model="udata.BenutzerID"
                :disabled="$g.user.Rolle !== 'admin'"
              />
            </b-form-group>

            <!-- Full Name -->
            <validation-provider
              #default="validationContext"
              name="Full Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="full-name"
              >
                <b-form-input
                  id="full-name"
                  v-model="udata.Name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Username -->
            <validation-provider
              #default="validationContext"
              name="Username"
              rules="required|alpha-num"
            >
              <b-form-group
                label="Vorname"
                label-for="username"
              >
                <b-form-input
                  id="username"
                  v-model="udata.Vorname"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              :rules="{ required: true, email: true, checkMailExists: [$g.manUserEdit, $g.currUserID, udata.BenID] }"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="udata.EMail"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Rolle"
              label-for="user-role"
            >
              <v-select
                v-model="udata.Rolle"
                :disabled="$g.user.BenutzerID === $g.currUserID || udata.Rolle === 'klient'"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>

            <b-form-group
              label="Status"
              label-for="user-status"
            >
              <v-select
                v-model="udata.Status"
                :disabled="$g.user.BenutzerID === $g.currUserID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid"
              >
                Speichern
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Abbrechen
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
// import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ben from '@/service/benutzer'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isBenutzerEditSidebarActive',
    event: 'update:is-benutzer-edit-sidebar-active',
  },
  props: {
    isBenutzerEditSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    this.currUserIDChg = null
    this.$g.manUserEdit = true
    return {
      required,
      alphaNum,
      email,
      udata: null,
    }
  },
  async created() { // 1st loading admin-user
    const r = await ben.getBenutzer(this.$g.currUserID)
    if (r !== null && r.data.length === 1) this.udata = r.data[0]
  },
  async updated() { // current user must be saved and merely be loaded from DB when necessary, otherwise the hook is permanently called, thus IIS-Pool is stopped!
    if ((this.currUserIDChg !== this.$g.currUserID) || (!this.$g.manUserEdit && this.udata.BenID !== null)) { // User changed or new user...
      this.currUserIDChg = this.$g.currUserID
      const r = await ben.getBenutzer(this.$g.currUserID)
      if (r !== null && r.data.length === 1) this.udata = r.data[0]
      if (!this.$g.manUserEdit) {
        this.udata.BenID = null
        this.udata.BenutzerID = ''
        this.udata.MandantID = this.$g.user.MandantID
        this.udata.Name = ''
        this.udata.Vorname = ''
        this.udata.EMail = ''
        this.udata.Rolle = 'berater'
        this.udata.Status = true
      }
    }
  },
  setup(props, { emit }) {
  // setup() {
    const resetuserData = () => {
      // userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const sprachen = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Français', value: 'fr' },
    ]

    const roleOptions = [
      // { label: 'Admin', value: 'admin' },
      { label: 'Mandant', value: 'mandant' },
      { label: 'Benutzer', value: 'berater' },
    ]

    const statusOptions = [
      { label: 'Aktiv', value: true },
      { label: 'Inaktiv', value: false },
    ]

    // const onSubmit = () => {
    //   emit('update:is-benutzer-edit-sidebar-active', false)
    // }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData, emit)

    return {
      sprachen,
      roleOptions,
      statusOptions,
      // onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  mounted() {
    extend('checkMailExists', {
      async validate(value, p) {
        if (p[0]) return true // edit mode...
        const r = await ben.getBenutzer(value)
        if (r !== null && r.data.length === 0) {
          return true
        }
        return 'Benutzer mit dieser E-Mail existiert bereits!'
      },
    })
  },
  methods: {
    async save() {
      try {
        if (this.$g.manUserEdit) {
          await ben.putBenutzer(this.udata)
          this.$emit('update:is-benutzer-edit-sidebar-active', false)
          this.$emit('refetch-users')
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Benutzer geändert',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Benutzerdaten erfolgreich geändert!',
              },
            },
            { position: 'bottom-right' },
          )
        } else {
          this.udata.BenID = -1
          this.udata.BenutzerID = this.udata.EMail
          this.$g.currUserID = this.udata.EMail
          await ben.newBenutzer(this.udata)
          this.$emit('update:is-benutzer-edit-sidebar-active', false)
          this.$emit('refetch-users')
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Benutzer hinzugefügt',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Neuer Benutzer erfolgreich hinzugefügt!',
              },
            },
            { position: 'bottom-right' },
          )
        }
        this.$g.manUserEdit = true
      } catch (error) {
        // console.log(error)
      }
    },
    async userNotExists(benutzerID) {
      // if (this.$g.manUserEdit) return true
      const r = await ben.getBenutzer(benutzerID)
      if (r !== null && r.data.length === 0) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
