<template>

  <b-card
    title="Mandanten-Benutzer"
  >
    <benutzer-edit
      :is-benutzer-edit-sidebar-active.sync="isBenutzerEditSidebarActive"
      @refetch-users="refetch"
    />
    <b-card-body>
      <div class="d-flex align-items-center justify-content-end">
        <b-form-input
          id="filterInput"
          v-model="filter"
          class="d-inline-block mr-1"
          placeholder="Suchen..."
        />
        <b-button
          variant="primary"
          class="btn-icon rounded-circle"
          @click="isBenutzerEditSidebarActive = true; $g.manUserEdit = false"
        >
          <feather-icon icon="UserPlusIcon" />
        </b-button>
        <!-- <b-button
          variant="primary"
          @click="isBenutzerEditSidebarActive = true; $g.manUserEdit = false"
        >
          <span class="text-nowrap">Neuer Benutzer</span>
        </b-button> -->
      </div>
    </b-card-body>

    <b-table
      ref="selectableTable"
      selectable
      select-mode="single"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      primary-key="BenID"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      @row-clicked="openActiveUser"
      @row-selected="onRowSelected"
    >
      <!-- <template #cell(Vorname)="data"> stehen lassen als Beispiel für Click-Button in Tabelle...
        <b-button
          size="sm"
          variant="empty"
          @click="isBenutzerEditSidebarActive = true"
        >
          {{ data.item.Vorname }}
        </b-button>
      </template> -->

      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Pro Seite"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody,
  // BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import man from '@/service/mandant'
import benutzerEdit from './BenutzerEdit.vue'

export default {
  components: {
    benutzerEdit,
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
  },
  setup() {
    const isBenutzerEditSidebarActive = ref(false)
    // const refetch = () => {
    //   this.refetch()
    // }
    return {
      isBenutzerEditSidebarActive,
    }
  },
  data() {
    this.$g.manUserEdit = false
    this.$g.currUserID = this.$g.user.BenutzerID
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        // { key: 'BenID', label: 'Id', isshow: false },
        { key: 'Vorname', label: 'Vorname', sortable: true },
        { key: 'Name', label: 'Name', sortable: true },
        { key: 'EMail', label: 'EMail', sortable: true },
        { key: 'Status', label: 'Status', sortable: true },
        { key: 'Rolle', label: 'Rolle', sortable: true },
      ],
      /* eslint-disable global-require */
      items: null,
      /* eslint-disable global-require */
      status: [{
        true: 'Aktiv', false: 'Inaktiv',
      },
      {
        true: 'light-success', false: 'light-warning',
      }],
      selected: [],
    }
  },
  computed: {
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter(f => f.sortable)
    //     .map(f => ({ text: f.label, value: f.key }))
    // },
  },
  async mounted() {
    this.$root.$refs.FormBenutzer = this
    const r = await man.getMandantBenutzer(this.$root.currMandantID) // this.$g.user.MandantID)
    if (r.data.length > 0) {
      this.items = r.data
      this.totalRows = this.items.length
    }
  },
  methods: {
    async refetch() {
      const r = await man.getMandantBenutzer(this.$root.currMandantID) // this.$g.user.MandantID)
      if (r.data.length > 0) {
        this.items = r.data
        this.totalRows = this.items.length
        let i = 0
        for (i; i < this.items.length && this.items[i].BenutzerID !== this.$g.currUserID; i += 1) ;
        this.currentPage = Math.trunc(i / this.perPage) + 1
        this.$refs.selectableTable.selectRow(i)
      }
    },
    onRowSelected(items) {
      this.$g.currUserID = items.length === 1 ? items[0].BenutzerID : ''
    },
    openActiveUser() {
      // console.log(this.isBenutzerEditSidebarActive)
      // console.log(this.$g.manUserEdit)
      this.isBenutzerEditSidebarActive = true
      this.$g.manUserEdit = true
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
