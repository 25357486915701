<template>
  <div v-if="!items">Loading Please wait...
  </div>
  <div v-else>
    <b-form>
      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="items"
        primary-key="VorlageMandantID"
        :fields="fields"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
        selected-variant="primary"
      >
        <template v-slot:cell(Name)="row">
          <b-form-textarea
              v-model="row.item.Name"
              rows="2"
              max-rows="4"
            />
        </template>
        <template v-slot:cell(UseStandard)="row">
          <b-form-checkbox
            :disabled="row.item.VorlageMandantID === null"
            v-model="row.item.UseStandard"
            class="custom-control-primary"
            name="check-button"
            switch
            @change="saveUseStandard(row.item)"
          />
        </template>
      </b-table>

      <b-row v-show="$g.user.Rolle === 'admin'">
        <b-col cols="12" md="4 mb-1 text-center">
          <strong>{{ selRow.VKey }}</strong>
        </b-col>

        <b-col cols="12" md="4 mb-1 text-center">
          <strong>{{ selRow.Name }}</strong>
        </b-col>
      </b-row>

      <b-row v-show="$g.user.Rolle === 'admin' || $g.user.Rolle === 'mandant'">
        <b-col cols="12" md="4 mb-1 text-center">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadDE"
          >
            Download Deutsch
          </b-button>
        </b-col>

        <b-col v-show="$g.user.Rolle === 'admin'" cols="12" md="4 mb-1 text-center">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadFR"
          >
            Download Französisch
          </b-button>
        </b-col>

        <b-col v-show="$g.user.Rolle === 'admin'" cols="12" md="4 mb-1 text-center">
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="downloadTransExcel"
          >
            Download TransExcel
          </b-button>
        </b-col>

      </b-row>

      <b-row v-show="$g.user.Rolle === 'admin' || $g.user.Rolle === 'mandant'">
        <b-col cols="12" md="4 text-center">
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileDE"
              :state="Boolean(fileDE)"
              placeholder="Deutsche Vorlage auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadDE"
          >
            Upload Deutsch
          </b-button>
        </b-col>

        <b-col v-show="$g.user.Rolle === 'admin'" cols="12" md="4 text-center">
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileFR"
              :state="Boolean(fileFR)"
              placeholder="Französische Vorlage auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadFR"
          >
            Upload Französisch
          </b-button>
        </b-col>

        <b-col v-show="$g.user.Rolle === 'admin'" cols="12" md="4 text-center">
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="fileTE"
              :state="Boolean(fileTE)"
              placeholder="TransExcel auswählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
          <b-button
            variant="primary"
            class="mb-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="uploadTransExcel"
          >
            Upload TransExcel
          </b-button>
        </b-col>

      </b-row>

    </b-form>
  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BTable, BFormFile, BButton, BForm, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import db from '@/service/vorlage'

export default {
  components: {
    BTable,
    BFormFile,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
  },
  data() {
    return {
      selRow: null,
      fileFR: null,
      fileDE: null,
      fileTE: null,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Beschrieb', label: 'Beschrieb' },
        { key: 'UseStandard', label: 'Benutze Standard' }, // eslint-disable-line
      ],
      /* eslint-disable global-require */
      items: null,
    }
  },
  async mounted() {
    const r = await db.getVorlagenMandant(this.$root.currMandantID, this.$g.user.Rolle !== 'admin')
    if (r.data.length > 0) {
      this.items = r.data
      this.totalRows = this.items.length
      this.selRow = this.items[0]
    }
  },
  methods: {
    onRowSelected(items) {
      if (items.length === 1) this.selRow = items[0]
    },
    async downloadDE() {
      const r = await db.getVorlagenDatei(this.selRow.VKey, this.$root.currMandantID, 'de')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.Name)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async downloadFR() {
      const r = await db.getVorlagenDatei(this.selRow.VKey, this.$root.currMandantID, 'fr')
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.NameFR)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async downloadTransExcel() {
      const r = await db.getVorlagenTransExcel(this.selRow.VKey, this.$root.currMandantID)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.selRow.Name.replace('.docx', '.xlsx'))
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async uploadDE() {
      if (this.fileDE === null) return
      const r = await db.postVorlage(this.fileDE, this.selRow.VKey, this.selRow.VorlageID, this.$root.currMandantID, 'de', this.selRow.VorlageMandantID)
      if (r.data !== null) this.selRow.VorlagenMandantID = r.data
      this.$root.showToast('Upload ' + this.selRow.Name, 'Upload Vorlage erfolgt')
    },
    async uploadFR() {
      if (this.fileFR === null) return
      const r = await db.postVorlage(this.fileFR, this.selRow.VKey, this.selRow.VorlageID, this.$root.currMandantID, 'fr', this.selRow.VorlageMandantID)
      if (r.data !== null) this.selRow.VorlagenMandantID = r.data
      this.$root.showToast('Upload ' + this.selRow.Name, 'Upload Vorlage erfolgt')
    },
    async uploadTransExcel() {
      if (this.fileTE === null) return
      await db.postVorlageTransExcel(this.fileTE, this.selRow.VKey, this.$root.currMandantID, this.selRow.VorlageMandantID, this.selRow.VorlageID)
      this.$root.showToast('Upload ' + this.selRow.Name, 'Upload TransExcel erfolgt')
    },
    async saveUseStandard(item) {
      if (item.VorlageMandantID !== null) await db.postVorlagenMandantStandardUpd(item.VorlageMandantID, item.UseStandard)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
