<template>
  <b-tabs
    pills
  >

    <!-- Tab: Mandant -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Mandant</span>
      </template>
      <mandant-detail
        class="mt-2 pt-75"
      />
    </b-tab>

    <!-- Tab: Benutzer -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Benutzer</span>
      </template>
      <benutzer class="mt-2 pt-75" />
    </b-tab>

    <!-- Tab: VorlageMandant -->
    <!-- <b-tab v-if="$g.user.Rolle === 'admin'"> -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="FileTextIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Vorlagen</span>
      </template>
      <vorlage-mandant class="mt-2 pt-75" />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
// import { ref } from '@vue/composition-api'
// import router from '@/router'
import MandantDetail from './Detail.vue'
import Benutzer from './Benutzer.vue'
import VorlageMandant from './VorlageMandant.vue'

export default {
  components: {
    BTab,
    BTabs,

    MandantDetail,
    Benutzer,
    VorlageMandant,
  },
}
</script>

<style>
</style>
