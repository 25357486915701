import axios from '@axios'

export default {
  async getBenutzerAbilities(rolle, dosID) {
    try {
      const r = await axios.get('benutzers/getBenutzerAbilities', {
        params: {
          rolle,
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBenutzer(email) {
    try {
      const r = await axios.get('benutzers/getBenutzerGet', {
        params: {
          email,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async newBenutzer(data) {
    try {
      const r = await axios.get('benutzers/getBenutzerInsert', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      this.errored = true
    }
    return null
  },
  async BenutzerInsert(MandantID, DosID, EMail, Sprache, Vorname, Name, Rolle) {
    try {
      const r = await axios.get('benutzers/getBenutzerInsert', {
        params: {
          MandantID,
          DosID,
          EMail,
          Sprache,
          Vorname,
          Name,
          Rolle,
        },
      })
      return r
    } catch (error) {
      this.errored = true
    }
    return null
  },
  async putBenutzer(data) {
    try {
      const r = await axios.get('benutzers/getBenutzerUpdate', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putBenutzerUpdateIstAGB(benID, istAGB) {
    try {
      const r = await axios.get('benutzers/getBenutzerUpdateIstAGB', {
        params: {
          benID,
          istAGB,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async vergessenPwd(email, sprache) {
    try {
      const r = await axios.get('benutzers/getpwdvergessen', {
        params: {
          email,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async changePwd(benID, curPwd, newPwd) {
    try {
      const r = await axios.get('benutzers/getchangepwd', {
        params: {
          benID,
          curPwd,
          newPwd,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async changeCurrDosID(benID, dosID) {
    try {
      const r = await axios.get('benutzers/getUpdateCurrDosID', {
        params: {
          benID,
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async sendMandantPwd(benID, benutzerID, sprache, email, curPwd) {
    try {
      const r = await axios.get('benutzers/getMandantPwd', {
        params: {
          benID,
          benutzerID,
          sprache,
          email,
          curPwd,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getIsTFA(benutzerID) {
    try {
      const r = await axios.get('benutzers/getIst2FA', {
        params: {
          benutzerID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getTFAQRCode(benutzerID) {
    try {
      const r = await axios.get('benutzers/getTFAQRCode', {
        params: {
          benutzerID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getTFAVerify(benutzerID, code) {
    try {
      const r = await axios.get('benutzers/getTFAVerify', {
        params: {
          benutzerID,
          code,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
