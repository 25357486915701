import axios from '@axios'

export default {
  async getHonorarKosten(dosID) {
    try {
      const r = await axios.get('honorar/getHonorarKosten', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getHonorar(honorarID, dosID) {
    try {
      const r = await axios.get('honorar/getHonorar', {
        params: {
          honorarID,
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async insUpdHonorar(data) {
    try {
      const r = await axios.get('honorar/getHonorarInsUpd', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async createHonorar(honorarID) {
    try {
      const r = await axios.get('honorar/getHonorarCreate', {
        params: {
          honorarID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getHonorarDatei(honorarID) { // Download...
    try {
      const r = await axios.get('honorar/getHonorarDatei', {
        responseType: 'blob',
        headers: { Accept: 'application/pdf' },
        params: {
          honorarID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async deleteHonorar(honorarID) {
    try {
      const r = await axios.get('honorar/getHonorarDelete', {
        params: {
          honorarID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async isValidIBAN(iban) {
    try {
      const r = await axios.get('honorar/getIsValidIBAN', {
        params: {
          iban,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
